import './App.css';
import Home from './pages/Home';
import ThemeSelect from '../src/components/ThemeSelect';

function App() {
	return (
		<div className="min-h-screen w-screen p-2 lg:p-6">
			<ThemeSelect />
			<Home />
		</div>
	);
}

export default App;
