import React from 'react';

export const Project = ({ project }) => (
	<>
		<div className="flex items-center mt-3">
			<a
				className="text-4xl mr-2"
				href={project.link}
				target="_blank"
				rel="noreferrer"
			>
				{project.icon}
			</a>
			<a
				className="link link-primary"
				href={project.link}
				target="_blank"
				rel="noreferrer"
			>
				<h2 className="text-2xl">{project.title}</h2>
			</a>
		</div>
		<p className="text-base text-primary">{project.caption}</p>
	</>
);
