import React from 'react';
import { FaXTwitter, FaGithub, FaLinkedin, FaCopy } from 'react-icons/fa6';

export const Contact = () => {
	const [copyTooltipText, setCopyTooltipText] = React.useState('copy email');

	const copyText = () => {
		setCopyTooltipText('copied!');
		navigator.clipboard.writeText('devanpellow@gmail.com');
		setTimeout(() => setCopyTooltipText('copy email'), 5000);
	};
	return (
		<div className="flex gap-4 justify-items-end pt-4">
			<a
				className="link link-primary"
				href="https://twitter.com/devanpellow"
				target="_blank"
				rel="noreferrer"
			>
				<FaXTwitter size={24} />
			</a>
			<a
				className="link link-primary"
				href="https://www.linkedin.com/in/devanpellow/"
				target="_blank"
				rel="noreferrer"
			>
				<FaLinkedin size={24} />
			</a>
			<a
				className="link link-primary"
				href="https://github.com/devanpellow"
				target="_blank"
				rel="noreferrer"
			>
				<FaGithub size={24} />
			</a>
			<div
				className="link link-primary flex items-center"
				onClick={() => {
					copyText();
				}}
			>
				devanpellow@gmail.com
				<div className="tooltip tooltip-accent ml-1" data-tip={copyTooltipText}>
					<FaCopy size={16} />
				</div>
			</div>
		</div>
	);
};
