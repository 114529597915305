import React from 'react';
import Headshot from '../images/peace_face.jpeg';
import Cards from '../components/Projects';
import { Contact } from '../components/Contact';

const Home = () => {
	const subscriberCount = 1318;

	return (
		<div className="max-w-2xl mx-auto">
			<div className="flex items-center space-x-4">
				<div>
					<img
						src={Headshot}
						alt=""
						className="
                        w-32 rounded-full ring ring-primary ring-offset-base-100 ring-offset-1 hover:animate-pulse"
					/>
				</div>
				<div className="">
					<span className="flex align-middle">
						<h1 className="font-bold mb-2">
							Hey, I'm Devan <span className="text-lg">👋</span>
						</h1>
					</span>
					<p>
						I work as a software engineer and I live in The Netherlands{' '}
						<span className="text-lg">🇳🇱</span>
					</p>
					<p>
						Building fun things on the web while trying to reach 5000
						subscribers on my newsletter <span className="text-lg">📬</span>
					</p>
					<div
						className="tooltip tooltip-bottom tooltip-accent"
						data-tip={subscriberCount}
					>
						<progress
							className="progress progress-info w-56 bg-white"
							value={subscriberCount}
							max="5000"
						></progress>
					</div>
				</div>
			</div>
			<Contact />
			<div className="divider"></div>
			<Cards />
		</div>
	);
};

export default Home;
