import React from 'react';
import { Project } from './Project';

const Projects = () => {
	const projects = [
		{
			title: 'Weekened Web Dev',
			icon: '📧',
			caption:
				"Weekly newsletter I send out with a web dev project tutorial and web apps I find during the week. I think you should totally subscribe. I hit my goal of 1000 subscribers last year, so now I'm aiming for 5000.",
			link: 'https://weekendwebdev.substack.com/',
		},
		// {
		// 	title: 'Website Business Pro',
		// 	icon: '🖥️',
		// 	caption:
		// 		"Helping people build websites for their businesses.",
		// 	link: 'https://websitebusinesspro.com/',
		// },
		{
			title: 'Pokédex',
			icon: '🔍',
			caption:
				'In 2021, I really wanted to master Tailwind. So I built a Pokédex with a ton of divs and tailwind classes. Inspect the page and you will see what I mean. It was a fun project. I used the PokéAPI to get the data.',
			link: 'https://pokedex-devan.netlify.app/',
		},
		{
			title: 'Invasion Game',
			icon: '👾',
			caption:
				"This was the first game I ever made back in 2019. I used the P5.js library to build it. It also never crossed my mind to make it mobile friendly, but I've since learned from my mistakes.",
			link: 'https://invasion-game.netlify.app/',
		},
	];

	return (
		<div className="grid grid-cols-1 gap-1">
			{projects.map((project) => (
				<Project key={project.title} project={project} />
			))}
		</div>
	);
};

export default Projects;
