import React from 'react';
import { useEffect } from 'react';
import { themeChange } from 'theme-change';

const ThemeSelect = () => {
	useEffect(() => {
		themeChange(false);
	}, []);

	return (
		<div className="float-right">
			<select
				className="select select-bordered select-xs w-max-content max-w-xs"
				data-choose-theme
			>
				<option className="text-center" disabled selected>
					🎨
				</option>
				<option value="aqua">Aqua</option>
				<option value="bumblebee">Bumblebee</option>
				<option value="cupcake">Cupcake</option>
				<option value="cyberpunk">Cyberpunk</option>
				<option value="dracula">Dracula</option>
				<option value="garden">Garden</option>
				<option value="halloween">Halloween</option>
				<option value="lemonade">Lemonade</option>
				<option value="retro">Retro</option>
				<option value="winter">Winter</option>
			</select>
		</div>
	);
};

export default ThemeSelect;
